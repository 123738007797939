import cx from "classnames";
import noop from "lodash/noop";
import { twMerge } from "tailwind-merge";
import React, { useRef } from "react";

import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";

const ChangeField = ({ isInvalid = false, label, prevValue, showClipboard, value, details }) => {
  const valueRef = useRef();
  const onValueFocus = (event) => {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNode(event.target);
    selection.removeAllRanges();
    selection.addRange(range);
  };
  const onLabelClick = () => {
    valueRef.current?.focus();
  };

  return (
    <div className="inline-flex items-stretch bg-zinc-100 rounded-full h-[24px]">
      <div
        className="cursor-pointer text-xs text-zinc-500/80 pl-3.5 pr-1 self-stretch flex items-center hover:text-zinc-500"
        onClick={isInvalid ? noop : onLabelClick}
      >
        <span>{label}</span>
      </div>
      <div
        className={twMerge(
          cx("bg-white rounded-full m-[1px] px-3.5 flex items-center shadow", {
            "bg-red-50 shadow-": isInvalid,
          })
        )}
      >
        {prevValue && prevValue !== value ? (
          <div className="bg-transparent border-none text-xs text-zinc-400/70 p-0 m-0 line-through flex items-center mr-1.5 hover:no-underline hover:text-zinc-400">
            {prevValue}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-3 h-3 ml-1.5 text-zinc-400"
            >
              <path
                fillRule="evenodd"
                d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : null}
        <div
          className={twMerge(
            cx("bg-transparent border-none text-xs text-zinc-500 p-0 m-0 font-medium", {
              "text-red-500": isInvalid,
            })
          )}
          onFocus={isInvalid ? noop : onValueFocus}
          ref={valueRef}
          tabIndex={0}
        >
          {value}
        </div>
        {showClipboard ? (
          <CopyToClipboardButton className="p-1 text-zinc-400 hover:text-zinc-600" text={value}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3.5 h-3.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
              />
            </svg>
          </CopyToClipboardButton>
        ) : null}
        {details ? (
          <Tooltip>
            <TooltipTrigger>
              <button
                aria-label="Details"
                className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-full h-full"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </TooltipTrigger>
            <TooltipContent>{details}</TooltipContent>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default ChangeField;
