export const normalizeValue = (value) => {
  return value === null || value === undefined ? "No Record" : value.toString();
};

export const formatDate = (value) => {
  return value === null || value === undefined ? "No Record" : new Date(value).toLocaleDateString();
};

export const formatBoolean = (value) => {
  return value === true ? "Yes" : "No";
};

export const formatSpecialEducationProgram = (value) => {
  switch (value) {
    case "sep_iep":
      return "IEP";
    case "sep_504":
      return "504";
    case "sep_iep_and_504":
      return "IEP & 504";
    default:
      return "None";
  }
};

export const formatSpecialEducationProgramAccommodations = (value) => {
  switch (value?.constructor?.name) {
    case "String":
      return [value];
    case "Array":
      return value;
    default:
      return ["No Record"];
  }
};

export const formatTeacherName = (student) => {
  if (student.teacher_first_name && student.teacher_last_name) {
    return `${student.teacher_last_name}, ${student.teacher_first_name}`;
  } else if (student.teacher_first_name) {
    return student.teacher_first_name;
  } else if (student.teacher_last_name) {
    return student.teacher_last_name;
  } else {
    return "No Record";
  }
};

export const minDate = (programTermStartDate) => {
  const currentDateObj = new Date();
  const programTermStartDateObj = new Date(programTermStartDate);
  if (!programTermStartDate || programTermStartDateObj < currentDateObj) {
    return currentDateObj.toISOString().split("T")[0];
  }
  return programTermStartDate;
};
