import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, post } from "../../api";

export const useStudentsForReviewQuery = () => {
  const { id: schoolId } = useParams();
  return useQuery({
    queryKey: ["students", schoolId],
    queryFn: async ({ signal }) => {
      const res = await get(`/admin/school_review/${schoolId}/students_for_review`, {
        signal,
      });
      return res.data;
    },
  });
};

export const useSchoolReviewMutation = ({ studentProgramEnrollments }) => {
  const { id: schoolId } = useParams();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return await post("/admin/school_review", {
        school_review_id: schoolId,
        student_program_enrollments: studentProgramEnrollments,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["students", schoolId] });
    },
  });
};
