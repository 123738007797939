import React from "react";
import { Link } from "react-router-dom";
import { Table } from "@Ignite-Reading/ui-kit/components";
import { timeToDateWithTime } from "../../utils";

const SchoolReview = ({ schools }) => {
  const data = schools.map((school) => ({
    id: school.id,
    name: school.name,
    submitted: timeToDateWithTime(school.updated_at),
    review_student_info: "Review Student Info",
    school_system_name: school.school_system_name,
  }));

  return (
    <div className="py-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">School Review</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of schools ready to be reviewed by Ignite Admins.
          </p>
        </div>
      </div>
      <div className="mt-6 pt-6 border-t border-gray-200 flex items-center"></div>
      <Table
        columns={[
          {
            id: "name",
            label: "Name",
          },
          {
            id: "submitted",
            label: "Submitted",
          },
          {
            id: "review_student_info",
            label: "Review Student Info",
          },
        ]}
        defaultSortKey="name"
        noDataMessage="There are no schools with student information ready to be reviewed."
        data={Object.entries(Object.groupBy(data, ({ school_system_name }) => school_system_name))}
      >
        {(data) =>
          data.map(([school_system_name, entries]) => [
            <Table.Row key={school_system_name}>
              <Table.Cell colSpan={5} className="bg-gray-100 text-zinc-600 font-semibold py-1.5">
                {school_system_name}
              </Table.Cell>
            </Table.Row>,
            entries.map((entry) => (
              <Table.Row key={entry.name}>
                <Table.Cell>{entry.name}</Table.Cell>
                <Table.Cell>{entry.submitted}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/school_review/${entry.id}/edit`}
                    className="text-brand-600 hover:text-brand-900"
                  >
                    Review Student Info
                  </Link>
                </Table.Cell>
              </Table.Row>
            )),
          ])
        }
      </Table>
    </div>
  );
};

export default SchoolReview;
