import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import { format, parse, parseISO } from "date-fns";
import React, { useContext, useState } from "react";

import { patch } from "../../api";
import ChangeField from "./ChangeField";
import { ChangesQueueContext } from "../../contexts/SessionChangesQueue";
import {
  MERIDIEM_TIME_FORMAT,
  MONTH_DAY_YEAR_DATE_FORMAT,
  SHORT_DATE_WITH_TIME,
  SHORT_DAY_OF_WEEK,
} from "../../constants";

const changeType = {
  DELETE_RECURRING: "delete_recurring",
  DELETE_SINGLE: "delete_single",
  CREATE_RECURRING: "create_recurring",
  CREATE_SINGLE: "create_single",
  UPDATE_RECURRING: "update_recurring",
  UPDATE_SINGLE: "update_single",
  CREATE_SCHOOL_NON_INSTRUCTIONAL_DAY: "create_school_non_instructional_day",
  DELETE_SCHOOL_NON_INSTRUCTIONAL_DAY: "delete_school_non_instructional_day",
};
const changeTypeToTitleMap = {
  [changeType.DELETE_RECURRING]: "Cancel recurring session",
  [changeType.DELETE_SINGLE]: "Cancel session",
  [changeType.CREATE_RECURRING]: "New recurring session",
  [changeType.CREATE_SINGLE]: "New session",
  [changeType.UPDATE_RECURRING]: "Update recurring session",
  [changeType.UPDATE_SINGLE]: "Update session",
  [changeType.CREATE_SCHOOL_NON_INSTRUCTIONAL_DAY]: "New School Non Instructional Day",
  [changeType.DELETE_SCHOOL_NON_INSTRUCTIONAL_DAY]: "Cancel School Non Instructional Day",
};
const fieldsToShowClipboard = ["school_name", "student_name", "tutor_name"];
const line0DiffFilterAndSortOrder = ["trigger", "timestamp", "status", "message"];
const line1DiffFilterAndSortOrder = [
  "school_name",
  "student_name",
  "student_grade_level",
  "tutor_name",
  "tutoring_block_times",
];
const line2DiffFilterAndSortOrder = [
  "days_of_week",
  "school_time_zone",
  "start_date",
  "start_time",
  "end_date",
  "end_time",
  "extra_tutors",
];

const formatTime = (value) => format(parseISO(value), MERIDIEM_TIME_FORMAT);
const formatDate = (value) => format(parseISO(value), MONTH_DAY_YEAR_DATE_FORMAT);
const formatDateTime = (value) => format(parseISO(value), SHORT_DATE_WITH_TIME);
const formatDayOfWeek = (day) =>
  format(parse(day, SHORT_DAY_OF_WEEK, new Date()), SHORT_DAY_OF_WEEK);
const formatDiffValue = (diff) => {
  if (["start_time", "end_time"].includes(diff.field)) {
    try {
      return {
        ...diff,
        value: formatTime(diff.value),
        ...(diff.old_value ? { old_value: formatTime(diff.old_value) } : {}),
      };
    } catch (e) {
      return {
        ...diff,
        isInvalid: true,
        value: "Invalid time",
        ...(diff.old_value ? { old_value: "Invalid time" } : {}),
      };
    }
  } else if (["start_date", "end_date"].includes(diff.field)) {
    try {
      return {
        ...diff,
        value: formatDate(diff.value),
        ...(diff.old_value ? { old_value: formatDate(diff.old_value) } : {}),
      };
    } catch (e) {
      return {
        ...diff,
        isInvalid: true,
        value: "Invalid Date",
        ...(diff.old_value ? { old_value: "Invalid Date" } : {}),
      };
    }
  } else if (diff.field === "days_of_week") {
    return {
      ...diff,
      value: diff.value.map(formatDayOfWeek).join(", "),
      ...(diff.old_value ? { old_value: diff.old_value.map(formatDayOfWeek).join(", ") } : {}),
    };
  } else if (diff.field === "timestamp") {
    try {
      return {
        ...diff,
        value: formatDateTime(diff.value),
        ...(diff.old_value ? { old_value: formatDateTime(diff.old_value) } : {}),
      };
    } catch (e) {
      return {
        ...diff,
        isInvalid: true,
        value: "Invalid Date",
        ...(diff.old_value ? { old_value: "Invalid Date" } : {}),
      };
    }
  }

  return diff;
};

const ChangesItem = ({ change_type, diffs, done, recurring, uuid }) => {
  const { setChanges } = useContext(ChangesQueueContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const mutation = useMutation({
    mutationFn: (event) => {
      event.preventDefault();

      return patch("session_changes/update.json", { done: true, uuid });
    },
    onMutate: () => {
      setIsProcessing(true);
    },
    onError: () => {
      setIsProcessing(false);
    },
    onSuccess: ({ data }) => {
      setIsProcessing(false);
      setChanges((changes) =>
        changes.map((change) => {
          return data.change.uuid === change.uuid ? data.change : change;
        })
      );
    },
  });
  const retryMutation = useMutation({
    mutationFn: (event) => {
      event.preventDefault();

      return patch("session_changes/update.json", { retry: true, uuid });
    },
    onMutate: () => {
      setIsRetrying(true);
    },
    onError: () => {
      setIsRetrying(false);
    },
    onSuccess: ({ data }) => {
      setChanges((changes) =>
        changes.map((change) => {
          return data.change.uuid === change.uuid ? data.change : change;
        })
      );
    },
  });
  const isCancelChangeType = [
    changeType.DELETE_RECURRING,
    changeType.DELETE_SINGLE,
    changeType.DELETE_SCHOOL_NON_INSTRUCTIONAL_DAY,
  ].includes(change_type);
  const isCreateChangeType = [
    changeType.CREATE_RECURRING,
    changeType.CREATE_SINGLE,
    changeType.CREATE_SCHOOL_NON_INSTRUCTIONAL_DAY,
  ].includes(change_type);
  const isUpdateChangeType = [changeType.UPDATE_RECURRING, changeType.UPDATE_SINGLE].includes(
    change_type
  );

  return (
    <form
      className={cx("border rounded-[14px]", {
        "bg-[#FEFBFB] border-[#F2DCD9]": isCancelChangeType,
        "bg-[#FBFDFE] border-[#D9E9F2]": isCreateChangeType,
        "bg-[#FEFDFB] border-[#F2E8D9]": isUpdateChangeType,
      })}
      onSubmit={mutation.mutate}
    >
      <div className="p-4">
        <div className="flex items-center pb-3">
          <span
            className={cx("w-2.5 h-2.5 rounded-full mr-1.5", {
              "bg-[#FF1F00]": isCancelChangeType,
              "bg-[#059CF1]": isCreateChangeType,
              "bg-[#FF9900]": isUpdateChangeType,
            })}
          />
          <h3
            className={cx("font-medium text-sm text-zinc-600 leading-none mr-1.5", {
              "line-through": done,
            })}
          >
            {changeTypeToTitleMap[change_type]}
          </h3>
          {recurring ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="text-zinc-400 w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"
              />
            </svg>
          ) : null}
        </div>
        <div className="flex flex-col gap-2">
          <ul className="flex flex-wrap gap-2">
            {diffs
              .filter((diff) => {
                return line0DiffFilterAndSortOrder.includes(diff.field);
              })
              .sort((a, b) => {
                return (
                  line0DiffFilterAndSortOrder.indexOf(a.field) -
                  line1DiffFilterAndSortOrder.indexOf(b.field)
                );
              })
              .map(formatDiffValue)
              .map((diff) => (
                <li key={diff.field}>
                  <ChangeField
                    label={diff.label}
                    prevValue={diff?.old_value}
                    value={diff.value}
                    showClipboard={fieldsToShowClipboard.includes(diff.field)}
                    details={diff?.details}
                    isInvalid={diff?.isInvalid}
                  />
                </li>
              ))}
          </ul>
          <ul className="flex flex-wrap gap-2">
            {diffs
              .filter((diff) => {
                return line1DiffFilterAndSortOrder.includes(diff.field);
              })
              .sort((a, b) => {
                return (
                  line1DiffFilterAndSortOrder.indexOf(a.field) -
                  line1DiffFilterAndSortOrder.indexOf(b.field)
                );
              })
              .map(formatDiffValue)
              .map((diff) => (
                <li key={diff.field}>
                  <ChangeField
                    label={diff.label}
                    prevValue={diff?.old_value}
                    value={diff.value}
                    showClipboard={fieldsToShowClipboard.includes(diff.field)}
                    details={diff?.details}
                    isInvalid={diff?.isInvalid}
                  />
                </li>
              ))}
          </ul>
          <ul className="flex flex-wrap gap-2">
            {diffs
              .filter((diff) => {
                return line2DiffFilterAndSortOrder.includes(diff.field);
              })
              .filter((diff) => {
                return diff.value !== null;
              })
              .sort((a, b) => {
                return (
                  line2DiffFilterAndSortOrder.indexOf(a.field) -
                  line2DiffFilterAndSortOrder.indexOf(b.field)
                );
              })
              .map(formatDiffValue)
              .map((diff) => (
                <li key={diff.field}>
                  <ChangeField
                    label={diff.label}
                    prevValue={diff?.old_value}
                    value={diff.value}
                    showClipboard={fieldsToShowClipboard.includes(diff.field)}
                    details={diff?.details}
                    isInvalid={diff?.isInvalid}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={cx("border-t p-2 rounded-b-[14px]", {
          "bg-[#FBF5F4] border-[#F2DCD9]": isCancelChangeType,
          "bg-[#F4F8FB] border-[#D9E9F2]": isCreateChangeType,
          "bg-[#FBF8F4] border-[#F2E8D9]": isUpdateChangeType,
        })}
      >
        <button
          className="button-secondary w-[116px]"
          disabled={done || isProcessing}
          type="submit"
        >
          {done ? (
            <div className="flex items-center justify-center relative pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="absolute -left-1.5 w-[18px] h-[18px]"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
              {isRetrying ? "Queued" : "Done"}
            </div>
          ) : (
            "Mark as done"
          )}
        </button>
        {done ? null : (
          <button
            className="button-secondary w-[116px] ml-2"
            disabled={isRetrying}
            type="button"
            onClick={retryMutation.mutate}
          >
            Retry
          </button>
        )}
      </div>
    </form>
  );
};

export default ChangesItem;
